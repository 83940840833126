/**
 * Attention: Never use this with `async/await` as it blocks the thread!
 */
function waitElementInViewport(container) {
  return new Promise(resolve => {
    if (window.IntersectionObserver) {
      new IntersectionObserver((entries, observer) => {
        entries.forEach(({
          isIntersecting
        }) => {
          if (isIntersecting) {
            resolve();
            observer.disconnect();
          }
        });
      }).observe(container);
    } else {
      resolve();
    }
  });
}

export { waitElementInViewport };