import { MEMORIZE_ELEMENTOR_HANDLER_PROPERTY } from ".";
/**
 * A content got unblocked, let's run custom triggers on them, for example
 * the Elementor ready hook.
 */

function customUnblockTriggers(nodes) {
  var _theme$initGoogleMap;

  const {
    elementorFrontend,
    TCB_Front,
    jQuery,
    showGoogleMap,
    et_pb_init_modules,
    et_calculate_fullscreen_section_size,
    tdYoutubePlayers,
    tdVimeoPlayers,
    FWP,
    avadaLightBoxInitializeLightbox,
    WPO_LazyLoad,
    mapsMarkerPro,
    theme,
    em_maps_load
  } = window;
  let foundWpgb = false; // https://www.mapsmarker.com/

  if (mapsMarkerPro) {
    Object.keys(mapsMarkerPro).forEach(k => mapsMarkerPro[k].main());
  } // https://ovatheme.com/


  theme === null || theme === void 0 ? void 0 : (_theme$initGoogleMap = theme.initGoogleMap) === null || _theme$initGoogleMap === void 0 ? void 0 : _theme$initGoogleMap.call(theme); // https://wordpress.org/plugins/events-manager/

  em_maps_load === null || em_maps_load === void 0 ? void 0 : em_maps_load();

  for (const {
    node
  } of nodes) {
    const {
      className,
      id
    } = node; // Elementor https://git.io/JsXoh

    if (!node.hasAttribute(MEMORIZE_ELEMENTOR_HANDLER_PROPERTY)) {
      // wait for all hook initializers for the ready callback
      setTimeout(() => elementorFrontend === null || elementorFrontend === void 0 ? void 0 : elementorFrontend.elementsHandler.runReadyTrigger(node), 0);
    } // WP Grid Builder


    if (id.startsWith("wpgb-") || className.startsWith("wpgb-")) {
      foundWpgb = true;
    } // Thrive Visual Editor


    if (TCB_Front && jQuery && className.indexOf("tcb-yt-bg") > -1) {
      const $node = jQuery(node);

      if ($node.is(":visible")) {
        TCB_Front.playBackgroundYoutube($node);
      }
    }
  } // Thrive Architect


  TCB_Front === null || TCB_Front === void 0 ? void 0 : TCB_Front.handleIframes(TCB_Front.$body, true); // https://avada.theme-fusion.com/design-elements/lightbox-element/

  avadaLightBoxInitializeLightbox === null || avadaLightBoxInitializeLightbox === void 0 ? void 0 : avadaLightBoxInitializeLightbox(); // FacetWP

  if (FWP) {
    FWP.loaded = false;
    FWP.refresh();
  } // WP Optimize lazyloading


  WPO_LazyLoad === null || WPO_LazyLoad === void 0 ? void 0 : WPO_LazyLoad.update(); // https://qodeinteractive.com/wordpress-theme/bridge-creative-multi-purpose-wordpress-theme/

  showGoogleMap === null || showGoogleMap === void 0 ? void 0 : showGoogleMap(); // jQuery plugins

  if (jQuery) {
    var _jQuery$lazyLoadXT, _jQuery;

    // https://github.com/ressio/lazy-load-xt
    (_jQuery$lazyLoadXT = (_jQuery = jQuery(window)).lazyLoadXT) === null || _jQuery$lazyLoadXT === void 0 ? void 0 : _jQuery$lazyLoadXT.call(_jQuery); // https://www.checkoutwc.com/documentation/how-to-enable-address-autocomplete/

    jQuery(document.body).trigger("cfw_load_google_autocomplete");
  } // Divi modules (e.g. Contact Forms and Newsletter signups)


  if (et_pb_init_modules) {
    // We need to reset the resize event listener as it results in an "resize" loop:
    // https://wordpress.org/support/topic/ultimatemember-profile-picture-upload-flickering/page/2/#post-15471580
    jQuery(window).off("resize", et_calculate_fullscreen_section_size);
    et_pb_init_modules();
  } // tagDiv Composer


  tdYoutubePlayers === null || tdYoutubePlayers === void 0 ? void 0 : tdYoutubePlayers.init();
  tdVimeoPlayers === null || tdVimeoPlayers === void 0 ? void 0 : tdVimeoPlayers.init(); // WP Grid Builder (the custom event triggers an AJAX request, so do this only if we unblock a WPGB element)

  try {
    foundWpgb && window.dispatchEvent(new CustomEvent("wpgb.loaded"));
  } catch (e) {// Silence is golden.
  }
}

export { customUnblockTriggers };