import { BANNER_SCALING_WHEN_WINDOW_WIDTH_LOWER, hexToRgb } from "../../..";
const ZINDEX = 999999999;

class BlockerHeroInlineStyles {
  static aspectRatioWrapper({
    blocker: {
      visualContentType,
      visualThumbnail: {
        width,
        height,
        forceRatio
      },
      isVisualDarkMode
    },
    customCss: {
      antiAdBlocker
    }
  }) {
    const style = {
      paddingTop: forceRatio ? "".concat(forceRatio, "%") : "".concat(height / width * 100, "%"),
      position: "relative"
    };

    if (visualContentType === "audio-player") {
      delete style.paddingTop;
      Object.assign(style, {
        height: 100,
        paddingTop: 0,
        background: "linear-gradient(315deg, ".concat(isVisualDarkMode ? "#2f3237 1%, #5e5e5e 100%" : "hsla(0, 0%, 89%, 1) 1%, hsla(0, 0%, 97%, 1) 100%", ")")
      });
    }

    return {
      className: "wp-exclude-emoji ".concat(antiAdBlocker === "y" ? "" : "rcb-hero-wrapper"),
      style
    };
  }

  static aspectRatioBackgroundWrapper({
    customCss: {
      antiAdBlocker
    },
    blocker: {
      visualContentType
    }
  }) {
    const style = {
      width: "100%",
      position: "absolute",
      inset: 0
    };

    if (visualContentType === "audio-player") {
      Object.assign(style, {
        width: 100,
        overflow: "hidden"
      });
    }

    return {
      className: antiAdBlocker === "y" ? undefined : "rcb-hero-bg-wrapper",
      style
    };
  }

  static aspectRatioBackground({
    customCss: {
      antiAdBlocker
    },
    blocker: {
      visualBlur,
      visualThumbnail: {
        url
      }
    }
  }) {
    const style = {
      background: "url('".concat(url, "') no-repeat center center"),
      backgroundSize: "cover",
      width: "100%",
      position: "absolute",
      inset: 0,
      filter: visualBlur > 0 ? "blur(".concat(visualBlur, "px)") : undefined
    };
    return {
      className: antiAdBlocker === "y" ? undefined : "rcb-hero-bg",
      style
    };
  }

  static aspectRatioContainer({
    blocker: {
      visualContentType,
      visualThumbnail: {
        hide,
        titleType = "top"
      }
    },
    customCss: {
      antiAdBlocker
    }
  }) {
    const style = {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: (hide === null || hide === void 0 ? void 0 : hide.indexOf("overlay")) > -1 ? undefined : "rgb(0 0 0 / 38%)",
      boxShadow: (hide === null || hide === void 0 ? void 0 : hide.indexOf("title")) > -1 || titleType === "center" ? undefined : "inset 0 115px 60px -60px rgb(0 0 0 / 77%)"
    };

    if (["audio-player"].indexOf(visualContentType) > -1) {
      delete style.boxShadow;
      delete style.background;
    }

    return {
      className: antiAdBlocker === "y" ? undefined : "rcb-hero-container",
      style
    };
  }

  static aspectRatioWholeClickable({
    customCss: {
      antiAdBlocker
    }
  }) {
    const style = {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 9
    };
    return {
      className: antiAdBlocker === "y" ? undefined : "rcb-hero-whole-clickable",
      style
    };
  }

  static playButtonWrapper({
    blocker: {
      presetId,
      visualContentType,
      isVisualDarkMode
    },
    bodyDesign: {
      acceptAllBg
    },
    customCss: {
      antiAdBlocker
    }
  }, isHover) {
    const isVimeo = presetId === "vimeo" && visualContentType === "video-player";
    const {
      r,
      g,
      b
    } = hexToRgb(acceptAllBg);
    const style = {
      cursor: "pointer",
      background: isVimeo ? "rgb(0 0 0 / 70%)" : "rgb(".concat(r, " ").concat(g, " ").concat(b, ")"),
      padding: "15px 30px",
      borderRadius: 5,
      zIndex: 9
    };

    if (visualContentType === "audio-player") {
      Object.assign(style, {
        position: "absolute",
        left: 120,
        top: 49,
        padding: "10px 10px 10px 13px",
        borderRadius: 99
      });

      if (isVisualDarkMode) {
        Object.assign(style, {
          background: "#111213"
        });
      }
    }

    if (isHover) {
      Object.assign(style, {
        background: isHover ? "rgb(26 183 234 / 100%)" : ""
      });
    }

    return {
      className: antiAdBlocker === "y" ? undefined : "rcb-hero-play-wrapper",
      style
    };
  }

  static playButton({
    blocker: {
      visualContentType
    },
    customCss: {
      antiAdBlocker
    }
  }, isHover) {
    const scalingFactor = visualContentType === "audio-player" ? 0.2 : 0.3;
    const style = {
      border: 0,
      background: "transparent",
      boxSizing: "border-box",
      width: 0,
      height: 60 * scalingFactor,
      borderColor: "transparent transparent transparent rgb(255 255 255 / ".concat(isHover ? 100 : 94, "%)"),
      transition: "100ms all ease",
      cursor: "pointer",
      borderStyle: "solid",
      borderWidth: "".concat(40 * scalingFactor, "px 0 ").concat(40 * scalingFactor, "px ").concat(60 * scalingFactor, "px")
    };
    return {
      className: antiAdBlocker === "y" ? undefined : "rcb-hero-play",
      style
    };
  }

  static title({
    blocker: {
      presetId,
      visualContentType,
      isVisualDarkMode,
      visualThumbnail: {
        titleType = "top"
      }
    },
    customCss: {
      antiAdBlocker
    }
  }) {
    const style = {
      position: "absolute",
      padding: "5px 10px",
      overflow: "hidden",
      maxWidth: "calc(100% - 140px)",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      color: "white",
      textShadow: "1px 1px 1px black",
      fontSize: 15,
      top: 10,
      left: 10
    };

    if (presetId === "vimeo" && visualContentType === "video-player" && titleType === "top") {
      Object.assign(style, {
        top: 20,
        left: 20,
        background: "#00000059",
        fontWeight: "bold",
        color: "rgb(0, 173, 239)",
        textShadow: undefined
      });
    } else if (visualContentType === "audio-player") {
      Object.assign(style, {
        left: 110,
        color: isVisualDarkMode ? "white" : "black",
        textShadow: "1px 1px 1px ".concat(isVisualDarkMode ? "black" : "white")
      });
    } else if (titleType === "center") {
      Object.assign(style, {
        position: undefined,
        background: "#0000007d",
        overflow: undefined,
        textOverflow: undefined,
        whiteSpace: undefined,
        textAlign: "center",
        marginBottom: 15
      });
    }

    return {
      className: "wp-exclude-emoji ".concat(antiAdBlocker === "y" ? "" : "rcb-hero-title"),
      style
    };
  }

  static defaultBlockerContainer({
    customCss: {
      antiAdBlocker
    }
  }, isVisible, rect) {
    const paddingLeftRight = window.innerWidth < BANNER_SCALING_WHEN_WINDOW_WIDTH_LOWER ? 5 : 30;
    const style = {
      position: "absolute",
      padding: "20px ".concat(paddingLeftRight, "px"),
      zIndex: ZINDEX,
      opacity: isVisible ? 1 : 0,
      pointerEvents: isVisible ? "all" : "none",
      display: "flex",
      transition: "opacity 300ms"
    }; // Calculate position inside our Portal relative to the entire document
    // See also https://stackoverflow.com/a/36520974/5506547

    if (rect) {
      const {
        top,
        left,
        width
      } = rect;
      const {
        scrollY,
        scrollX
      } = window;
      Object.assign(style, {
        top: top + scrollY,
        left: left + scrollX,
        width: width - paddingLeftRight * 2
      });
    }

    return {
      className: antiAdBlocker === "y" ? undefined : "rcb-db-container",
      style
    };
  }

  static defaultBlockerOverlay({
    customCss: {
      antiAdBlocker
    }
  }, isVisible) {
    const style = {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: ZINDEX,
      background: "#000000cf",
      opacity: isVisible ? 1 : 0,
      pointerEvents: isVisible ? "all" : "none",
      transition: "opacity 300ms"
    };
    return {
      className: antiAdBlocker === "y" ? undefined : "rcb-db-overlay",
      style
    };
  }

}

export { BlockerHeroInlineStyles };