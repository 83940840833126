import { useMemo } from "react";
import { useBlocker } from "../../../../contexts";

const BlockerHeroAudioWaveFromText = ({
  text
}) => {
  const {
    blocker: {
      isVisualDarkMode
    },
    bodyDesign: {
      acceptAllBg
    }
  } = useBlocker();
  const waveIntegers = useMemo(() => {
    let numbers = [];
    text.split("").forEach(c => c.charCodeAt(0).toString().split("").map(Number).forEach(n => numbers.push(n))); // Remove zeros and treat as 1

    numbers = numbers.map(number => number === 0 ? 1 : number); // Remove duplicates

    numbers = numbers.map((number, i) => i > 0 ? numbers[i - 1] === number ? false : number : number).filter(Boolean); // Correct the wave to be more nicer by removing absolute opposites

    numbers = numbers.map((number, i) => {
      if (i === 0 || i === numbers.length - 1) {
        return number;
      }

      if (Math.abs(numbers[i - 1] - number) >= 4 && Math.abs(numbers[i + 1] - number)) {
        return false;
      }

      return number;
    }).filter(Boolean); // Make sure to hold a lot of items to fill the line

    for (let i = 0; i < 100; i++) {
      if (numbers.length > 500) {
        break;
      }

      numbers = [...numbers, ...numbers];
    }

    return numbers;
  }, [text]);
  return h("div", {
    style: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      position: "absolute",
      left: 170,
      top: 44,
      right: 20,
      height: 46,
      overflow: "hidden"
    }
  }, waveIntegers.map((int, index) => h("div", {
    key: index,
    style: {
      height: "".concat(int * 10, "%"),
      background: isVisualDarkMode ? "white" : acceptAllBg,
      paddingLeft: 2,
      marginLeft: index === 0 ? 0 : 2
    }
  })));
};

export { BlockerHeroAudioWaveFromText };