class BlockerHeaderInlineStyles {
  static headerContainer({
    layout: {
      type,
      dialogBorderRadius
    },
    design: {
      borderWidth,
      borderColor,
      ...restDesign
    },
    headerDesign: {
      inheritBg,
      bg,
      padding
    },
    customCss: {
      antiAdBlocker
    }
  }) {
    const style = {
      padding: padding.map(p => "".concat(p, "px")).join(" "),
      background: inheritBg ? restDesign.bg : bg,
      borderRadius: type === "dialog" ? "".concat(dialogBorderRadius, "px ").concat(dialogBorderRadius, "px 0 0") : undefined
    };

    if (type === "dialog" && borderWidth > 0) {
      style.borderTop = "".concat(borderWidth, "px solid ").concat(borderColor);
      style.borderLeft = style.borderTop;
      style.borderRight = style.borderTop;
    }

    return {
      className: antiAdBlocker === "y" ? undefined : "rcb-header-container",
      style
    };
  }

  static header({
    design: {
      textAlign
    },
    headerDesign: {
      inheritTextAlign,
      ...restHeaderDesign
    },
    customCss: {
      antiAdBlocker
    }
  }) {
    const useTextAlign = inheritTextAlign ? textAlign : restHeaderDesign.textAlign;
    return {
      className: antiAdBlocker === "y" ? undefined : "rcb-header",
      style: {
        margin: "auto",
        display: "flex",
        justifyContent: useTextAlign === "center" ? "center" : useTextAlign === "right" ? "flex-end" : undefined,
        alignItems: "center",
        position: "relative"
      }
    };
  }

  static headerSeparator({
    layout: {
      type
    },
    design,
    headerDesign: {
      borderWidth,
      borderColor
    },
    customCss: {
      antiAdBlocker
    }
  }) {
    const style = {
      height: +borderWidth,
      background: borderColor
    };

    if (type === "dialog" && design.borderWidth > 0) {
      style.borderLeft = "".concat(design.borderWidth, "px solid ").concat(design.borderColor);
      style.borderRight = style.borderLeft;
    }

    return {
      className: antiAdBlocker === "y" ? undefined : "rcb-header-separator",
      style
    };
  }

}

export { BlockerHeaderInlineStyles };