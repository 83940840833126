const MEMORIZE_JQUERY_EVENT_PROPERTY = "rcbJQueryEventListenerMemorize";
/**
 * We listen to the passed event and once that event got triggered, all event registrations
 * afterwards are automatically executed, too. This does not need another trigger again.
 *
 * You need to call this function as early as possible to catch all triggers.
 */

function memorizeJQueryEvent(doc, element, eventName) {
  const memorizeProp = "".concat(MEMORIZE_JQUERY_EVENT_PROPERTY, "_").concat(eventName);
  const {
    jQuery
  } = doc.defaultView || doc.parentWindow;

  if (!jQuery) {
    return;
  }

  const {
    event,
    Event
  } = jQuery;

  if (!event || !Event || event[memorizeProp]) {
    return;
  } // TODO: shouldn't this be memorized to the element itself?


  Object.assign(event, {
    [memorizeProp]: new Promise(resolve => jQuery(element).on(eventName, (...args) => resolve(args)))
  });
}

export { memorizeJQueryEvent, MEMORIZE_JQUERY_EVENT_PROPERTY };