import { calculateScore } from ".";
import { matchingSelector } from ".";

function iterateRules(rules, element, scoresPool, property) {
  for (const ruleIdx in rules) {
    const rule = rules[ruleIdx];

    if (!(rule instanceof CSSStyleRule)) {
      continue;
    }

    try {
      if (matchingSelector(element, rule.selectorText)) {
        const style = rule.style[property];

        if (style !== undefined && style !== "") {
          scoresPool.push({ ...calculateScore(rule, scoresPool.length, property),
            style
          });
        }
      }
    } catch (e) {// Silence is golden.
    }
  }
}

export { iterateRules };