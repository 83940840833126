import { putHtmlCodeToDom, ensureScriptWrapper, HTML_ATTRIBUTE_INLINE, HTML_TAG_CONSENT_SCRIPT, HTML_ATTRIBUTE_CAPTURE_PREFIX, HTML_ATTRIBUTE_CAPTURE_SUFFIX } from ".";
/**
 * Put an prepared inline script (tag containing a `consent-inline` attribute) to the DOM.
 */

function putScriptInlineToDom(node) {
  const isInHead = node.parentElement === document.head; // Get the inline script itself

  const inlineScript = node.getAttribute(HTML_ATTRIBUTE_INLINE);
  node.removeAttribute(HTML_ATTRIBUTE_INLINE); // Sometimes, the inline scripts writes new content via `document.write` and we need to make this visible

  node.style.removeProperty("display"); // Transform the tag to the `script`
  // We need to make such a hacky way because regex does not work and `outerHTML` has also some issues...

  let inlineScriptHtml = node.outerHTML.substr(HTML_TAG_CONSENT_SCRIPT.length + 1);
  inlineScriptHtml = inlineScriptHtml.substr(0, inlineScriptHtml.length - HTML_TAG_CONSENT_SCRIPT.length - 3);
  inlineScriptHtml = inlineScriptHtml.replace(new RegExp("type=\"application/consent\""), "");
  inlineScriptHtml = inlineScriptHtml.replace(new RegExp("".concat(HTML_ATTRIBUTE_CAPTURE_PREFIX, "-type-").concat(HTML_ATTRIBUTE_CAPTURE_SUFFIX, "=\"([^\"]+)\"")), "type=\"$1\"");
  inlineScriptHtml = "<script".concat(inlineScriptHtml).concat(inlineScript, "</script>");
  return isInHead ? putHtmlCodeToDom(inlineScriptHtml, {}) : ensureScriptWrapper(inlineScriptHtml, node);
}

export { putScriptInlineToDom };