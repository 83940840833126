class BlockerGroupsInlineStyles {
  static cookieScroll({
    design: {
      fontSize
    },
    bodyDesign: {
      descriptionInheritFontSize,
      descriptionFontSize
    },
    customCss: {
      antiAdBlocker
    }
  }) {
    return {
      className: antiAdBlocker === "y" ? undefined : "rcb-cookie-scroll",
      style: {
        fontSize: descriptionInheritFontSize ? +fontSize : +descriptionFontSize,
        textAlign: "left",
        marginBottom: 10,
        maxHeight: 400,
        overflowY: "scroll",
        paddingRight: 10
      }
    };
  }

}

export { BlockerGroupsInlineStyles };