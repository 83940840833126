import { useEffect, useState } from "react";
import { waitElementInViewport } from "../utils";

function useWaitElementInViewport(ref) {
  const [inViewport, setInViewport] = useState(false);
  useEffect(() => {
    if (ref.current) {
      waitElementInViewport(ref.current).then(() => {
        setInViewport(true);
      });
    }
  }, []);
  return inViewport;
}

export { useWaitElementInViewport };