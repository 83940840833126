class BlockerButtonsInlineStyles {
  static save({
    decision: {
      acceptAll
    },
    layout: {
      borderRadius
    },
    bodyDesign: {
      acceptAllFontSize,
      acceptAllBg,
      acceptAllTextAlign,
      acceptAllBorderColor,
      acceptAllPadding,
      acceptAllBorderWidth,
      acceptAllFontColor,
      acceptAllHoverBg,
      acceptAllHoverFontColor,
      acceptAllHoverBorderColor
    },
    customCss: {
      antiAdBlocker
    }
  }, isHover, framed) {
    return this.common({
      name: "accept-all",
      type: acceptAll,
      borderRadius,
      bg: acceptAllBg,
      hoverBg: acceptAllHoverBg,
      fontSize: acceptAllFontSize,
      textAlign: acceptAllTextAlign,
      fontColor: acceptAllFontColor,
      hoverFontColor: acceptAllHoverFontColor,
      borderWidth: acceptAllBorderWidth,
      borderColor: acceptAllBorderColor,
      hoverBorderColor: acceptAllHoverBorderColor,
      padding: acceptAllPadding,
      antiAdBlocker
    }, isHover, framed);
  }

  static showInfo({
    decision: {
      acceptIndividual
    },
    layout: {
      borderRadius
    },
    bodyDesign: {
      acceptIndividualFontSize,
      acceptIndividualBg,
      acceptIndividualTextAlign,
      acceptIndividualBorderColor,
      acceptIndividualPadding,
      acceptIndividualBorderWidth,
      acceptIndividualFontColor,
      acceptIndividualHoverBg,
      acceptIndividualHoverFontColor,
      acceptIndividualHoverBorderColor
    },
    customCss: {
      antiAdBlocker
    }
  }, isHover, framed) {
    return this.common({
      name: "accept-individual",
      type: acceptIndividual,
      borderRadius,
      bg: acceptIndividualBg,
      hoverBg: acceptIndividualHoverBg,
      fontSize: acceptIndividualFontSize,
      textAlign: acceptIndividualTextAlign,
      fontColor: acceptIndividualFontColor,
      hoverFontColor: acceptIndividualHoverFontColor,
      borderWidth: acceptIndividualBorderWidth,
      borderColor: acceptIndividualBorderColor,
      hoverBorderColor: acceptIndividualHoverBorderColor,
      padding: acceptIndividualPadding,
      antiAdBlocker
    }, isHover, framed);
  }

  static hero({
    decision: {
      acceptAll
    },
    layout: {
      borderRadius
    },
    bodyDesign: {
      acceptAllFontSize,
      acceptAllBg,
      acceptAllTextAlign,
      acceptAllBorderColor,
      acceptAllPadding,
      acceptAllBorderWidth,
      acceptAllFontColor,
      acceptAllHoverBg,
      acceptAllHoverFontColor,
      acceptAllHoverBorderColor
    },
    customCss: {
      antiAdBlocker
    }
  }, isHover, framed) {
    return this.common({
      name: "accept-all",
      type: acceptAll,
      borderRadius,
      bg: acceptAllBg,
      hoverBg: acceptAllHoverBg,
      fontSize: acceptAllFontSize,
      textAlign: acceptAllTextAlign,
      fontColor: acceptAllFontColor,
      hoverFontColor: acceptAllHoverFontColor,
      borderWidth: acceptAllBorderWidth,
      borderColor: acceptAllBorderColor,
      hoverBorderColor: acceptAllHoverBorderColor,
      padding: acceptAllPadding,
      boxShadow: "rgb(0 0 0 / 15%) 0px 0px 100px 30px, rgb(0 0 0 / 40%) 0px 2px 5px 1px",
      zIndex: 9,
      antiAdBlocker
    }, isHover, framed);
  }

  static common({
    name,
    type,
    borderRadius,
    bg,
    hoverBg,
    fontSize,
    textAlign,
    fontColor,
    hoverFontColor,
    borderWidth,
    borderColor,
    hoverBorderColor,
    padding,
    boxShadow,
    zIndex,
    antiAdBlocker
  }, isHover, framed) {
    const style = {
      textDecoration: type === "link" ? "underline" : "none",
      borderRadius: +borderRadius,
      cursor: type === "button" ? "pointer" : undefined,
      backgroundColor: type === "button" ? isHover ? hoverBg : bg : undefined,
      fontSize: +fontSize,
      textAlign,
      color: isHover ? hoverFontColor : fontColor,
      transition: "background-color 250ms, color 250ms, border-color 250ms",
      marginBottom: 10,
      border: type === "button" && borderWidth > 0 ? "".concat(borderWidth, "px solid ").concat(isHover ? hoverBorderColor : borderColor) : undefined,
      padding: padding.map(p => "".concat(p, "px")).join(" "),
      overflow: "hidden",
      outline: framed ? "rgb(255, 94, 94) solid 5px" : undefined,
      boxShadow,
      zIndex
    };
    return {
      className: antiAdBlocker === "y" ? undefined : "rcb-btn-".concat(name),
      style
    };
  }

}

export { BlockerButtonsInlineStyles };