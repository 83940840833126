/**
 * For a list of unblocked nodes, check if it are a list of `<source`s and load the parent `<video`.
 *
 * @see https://stackoverflow.com/a/18454389/5506547
 */
function loadVideoSource(unblockedNodes) {
  const foundVideos = unblockedNodes.filter(({
    node: {
      nodeName,
      parentElement
    }
  }) => nodeName === "SOURCE" && parentElement.nodeName === "VIDEO").map(({
    node: {
      parentElement
    }
  }) => parentElement); // Unique it to avoid multiple load's

  foundVideos.filter((value, index) => foundVideos.indexOf(value) === index).forEach(video => video.load());
}

export { loadVideoSource };