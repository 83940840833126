import { putHtmlCodeToDom, HTML_ATTRIBUTE_TRANSFORM_WRAPPER, HTML_ATTRIBUTE_TRANSFORM_WRAPPER_VALUE } from ".";
/**
 * We do need to create a `span` for postscribe cause the <script> tag can not be used in this case.
 *
 * @param html The `script` tag HTML (can be inline or with `src`)
 */

function ensureScriptWrapper(html, node) {
  // Check if there is already a wrapper known
  const previousElementSibling = node.previousElementSibling; // Got the element already be moved by another script? So we just ignore as this
  // is currently an edge-case (e.g. Podigee Podcast Player).

  if (!node.parentElement) {
    return Promise.resolve();
  }

  let referenceNode;

  if (previousElementSibling !== null && previousElementSibling !== void 0 && previousElementSibling.hasAttribute(HTML_ATTRIBUTE_TRANSFORM_WRAPPER)) {
    referenceNode = previousElementSibling;
  } else {
    referenceNode = document.createElement("div"); // we need to have a `block`ing element

    referenceNode.setAttribute(HTML_ATTRIBUTE_TRANSFORM_WRAPPER, HTML_ATTRIBUTE_TRANSFORM_WRAPPER_VALUE);
    node.parentElement.replaceChild(referenceNode, node);
  }

  return putHtmlCodeToDom(html, {}, referenceNode);
}

export { ensureScriptWrapper };