import { OPT_IN_CONTENT_BLOCKER_ALL, isCurrentlyInTransaction } from "../..";
import { MEMORIZE_NATIVE_EVENT_PROPERTY, dispatchInitiatorExecution } from ".";
const OVERWRITE_PROPERTY = "rcbNativeEventListener";
/**
 * Overwrite `window.addEventListener('load')` as they can not be triggered by our script blocker.
 * This can also be used for other known events.
 */

function applyNativeEventListenerInitiator(element, eventName, {
  onBeforeExecute
} = {
  onBeforeExecute: undefined
}) {
  const overwriteProp = "".concat(OVERWRITE_PROPERTY, "_").concat(eventName);
  const memorizeProp = "".concat(MEMORIZE_NATIVE_EVENT_PROPERTY, "_").concat(eventName); // Only overwrite once

  if (element[overwriteProp]) {
    return;
  }

  const {
    addEventListener
  } = element;
  Object.assign(element, {
    [overwriteProp]: true,
    addEventListener: function (type, ...rest) {
      if (type === eventName) {
        // Redirect to own thread to avoid variable order lacks (e. g. Uncode Gmaps Integration, Contact Form 7
        const executeHandle = () => setTimeout(() => {
          var _rest$;

          const afterExecution = dispatchInitiatorExecution({
            type: "nativeEvent",
            eventName
          });
          onBeforeExecute === null || onBeforeExecute === void 0 ? void 0 : onBeforeExecute();
          (_rest$ = rest[0]) === null || _rest$ === void 0 ? void 0 : _rest$.call(rest, new Event(eventName, {
            bubbles: true,
            cancelable: true
          }));
          afterExecution();
        }, 0);

        if (isCurrentlyInTransaction()) {
          const memorizeExecutionPromise = element[memorizeProp];
          let executed = false;
          document.addEventListener(OPT_IN_CONTENT_BLOCKER_ALL, () => {
            // IE does not support the `once` option
            if (executed) {
              return;
            }

            executed = true;

            if (memorizeExecutionPromise) {
              memorizeExecutionPromise.then(executeHandle);
            } else {
              executeHandle();
            }
          });
        } else {
          executeHandle();
        }
      } else {
        addEventListener.apply(this, [type, ...rest]);
      }
    }
  });
}

export { applyNativeEventListenerInitiator };