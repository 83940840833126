import { useBlocker, useCommonFooterLinks, BlockerFooterInlineStyles } from "../../..";

const BlockerFooter = () => {
  const blocker = useBlocker();
  const {
    rows: links,
    render: renderLinks
  } = useCommonFooterLinks({
    putPoweredByLinkInRow: 1
  });
  return h("div", BlockerFooterInlineStyles.footerContainer(blocker), h("div", BlockerFooterInlineStyles.footer(blocker), renderLinks(links)));
};

export { BlockerFooter };