import { calculateInlineScore, iterateStylesheets, sortScores } from ".";

function resolveScorePool(element, property) {
  const scores = []; // Iterate all our stylesheets

  iterateStylesheets(element, scores, property); // Calculate by inline style

  const inline = calculateInlineScore(element, property);
  inline && scores.push(inline); // Prepare result

  if (!scores.length) {
    return undefined;
  } // Sort scores


  sortScores(scores);
  return scores;
}

export { resolveScorePool };