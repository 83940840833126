import { iterateRules } from ".";

function iterateStylesheets(element, scores, property) {
  const {
    styleSheets
  } = document;

  for (const styleSheetIdx in styleSheets) {
    const styleSheet = styleSheets[styleSheetIdx];
    let rules;

    try {
      rules = styleSheet.cssRules || styleSheet.rules;
    } catch (e) {
      // Rules could not be read, e.g. CORS policy (https://stackoverflow.com/a/49994161/5506547)
      continue;
    }

    if (!rules) {
      continue;
    }

    iterateRules(rules, element, scores, property);
  }
}

export { iterateStylesheets };