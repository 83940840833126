// import $ from "jquery"; // not always available
import { HTML_ATTRIBUTE_BLOCKER_ID, HTML_ATTRIBUTE_UNBLOCKED_TRANSACTION_COMPLETE, OPT_IN_CONTENT_BLOCKER_ALL } from "..";
const ALREADY_OVERWRITTEN_PROPERTY = "listenOptInJqueryFnForContentBlockerNow";
const BLOCKED_ELEMENT_SELECTOR = "[".concat(HTML_ATTRIBUTE_BLOCKER_ID, "]:not([").concat(HTML_ATTRIBUTE_UNBLOCKED_TRANSACTION_COMPLETE, "])");
/**
 * Modify a jQuery function to wait for consent if a content blocker is within the
 * given selector.
 */

function hijackJqueryFn(fns) {
  const $ = window.jQuery;

  if (!($ !== null && $ !== void 0 && $.fn)) {
    return;
  }

  const jQueryFns = $.fn;

  for (const fn of fns) {
    const originalFunction = jQueryFns[fn];

    if (!originalFunction) {
      continue;
    } // Already overwritten?


    const alreadyOverwritten = jQueryFns[ALREADY_OVERWRITTEN_PROPERTY] = jQueryFns[ALREADY_OVERWRITTEN_PROPERTY] || [];

    if (alreadyOverwritten.indexOf(fn) > -1) {
      continue;
    }

    alreadyOverwritten.push(fn); // Memorize the static properties (e.g. `jQuery.fn.{fn}.defaults` (https://github.com/marioestrada/jQuery-gMap/blob/33b2771a5789b7531eb56878cfa3e4a0c30a8c43/jquery.gmap.js#L250))

    const staticProps = Object.getOwnPropertyDescriptors(originalFunction);
    delete staticProps.length;
    delete staticProps.name;
    delete staticProps.prototype;

    jQueryFns[fn] = function (...args) {
      if (this.length) {
        const notBlockedElements = [];

        for (const element of this.get()) {
          var _element$matches;

          const blockedElements = Array.prototype.slice.call(element.querySelectorAll(BLOCKED_ELEMENT_SELECTOR)); // Also respect own element (element can also be `document` which does not have a `matches` function)

          if ((_element$matches = element.matches) !== null && _element$matches !== void 0 && _element$matches.call(element, BLOCKED_ELEMENT_SELECTOR)) {
            blockedElements.push(element);
          } // Call original function immediate if no content blocker found


          if (blockedElements.length) {
            // Wait for all unblocked content
            // TODO: should we also create chunks per `consent-id`?
            Promise.all(blockedElements.map(node => new Promise(resolve => node.addEventListener(OPT_IN_CONTENT_BLOCKER_ALL, resolve)))).then(() => originalFunction.apply($(element), args));
          } else {
            notBlockedElements.push(element);
          }
        }

        return originalFunction.apply(jQuery(notBlockedElements), args);
      } else {
        // There is no jQuery object passed to the jQuery function. But we need to call the
        // original function anyway as some properties of e.g. `$.fn.prettyPhoto` can be
        // lazy loaded and expect a "first" call
        return originalFunction.apply($(this), args);
      }
    }; // Apply memorized static properties


    Object.defineProperties(jQueryFns[fn], staticProps);
  }
}

export { hijackJqueryFn };