import { INITIATOR_EXECUTION } from "../../events";

const dispatchInitiatorExecution = detail => {
  document.dispatchEvent(new CustomEvent(INITIATOR_EXECUTION, {
    detail: {
      position: 0,
      ...detail
    }
  }));
  return () => document.dispatchEvent(new CustomEvent(INITIATOR_EXECUTION, {
    detail: {
      position: 1,
      ...detail
    }
  }));
};

export { dispatchInitiatorExecution };