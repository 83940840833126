import { idx } from "@devowl-wp/cookie-consent-web-client";
import { OPT_IN_CONTENT_BLOCKER_ALL, OPT_IN_CONTENT_BLOCKER, HTML_ATTRIBUTE_COOKIE_IDS } from "@devowl-wp/headless-content-unblocker";
const MEMORIZE_ELEMENTOR_HANDLER_PROPERTY = "rcb-overwritten";

function overwrite(prototype, {
  delay,
  optIn,
  optInAll
}) {
  const {
    onInit,
    [MEMORIZE_ELEMENTOR_HANDLER_PROPERTY]: already
  } = prototype;

  if (already) {
    return;
  }

  prototype[MEMORIZE_ELEMENTOR_HANDLER_PROPERTY] = true; // `onInit` is called for each element, even it is blocked

  prototype.onInit = function (...args) {
    const $element = this.$element;
    const element = $element.get(0);

    if ($element.attr(HTML_ATTRIBUTE_COOKIE_IDS)) {
      $element.attr(MEMORIZE_ELEMENTOR_HANDLER_PROPERTY, "1");
      element.addEventListener(OPT_IN_CONTENT_BLOCKER, ({
        detail
      }) => {
        optIn === null || optIn === void 0 ? void 0 : optIn($element, detail, this);
      });
      element.addEventListener(OPT_IN_CONTENT_BLOCKER_ALL, ({
        detail
      }) => {
        optInAll === null || optInAll === void 0 ? void 0 : optInAll($element, detail, this);
        setTimeout(() => onInit.apply(this, args), delay || 0);
      });
    } else {
      return onInit.apply(this, args);
    }
  };
}
/**
 * Overwrite Elementor modules and wait until consent is given for blocked module.
 * Passed `handlers` can be widget names like `video.default` or class names `VideoPlaylistHandler`
 * for dynamic modules.
 */


function hijackElementorOnInit(handlers) {
  const {
    wrapFn
  } = window.consentApi;
  wrapFn({
    // See https://github.com/elementor/elementor/blob/2ee675caf03908f8c66e16298e923a24b206df54/assets/dev/js/frontend/frontend.js#L319-L322
    object: () => idx(window, window => window.elementorFrontend),
    key: "initModules"
  }, ({
    objectResolved
  }) => {
    wrapFn({
      object: objectResolved.elementsHandler,
      key: "addHandler"
    }, ({
      args: [handlerClass]
    }) => {
      for (const handler of handlers) {
        if (handlerClass.name === handler.className) {
          overwrite(handlerClass.prototype, handler);
        }
      }

      return true;
    });
    return true;
  });
}

export { hijackElementorOnInit, MEMORIZE_ELEMENTOR_HANDLER_PROPERTY };