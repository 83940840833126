class BlockerInlineStyles {
  static topSide({
    customCss: {
      antiAdBlocker
    }
  }) {
    const style = {
      marginBottom: 15
    };
    return {
      className: antiAdBlocker === "y" ? undefined : "rcb-tb-top",
      style
    };
  }

  static bottomSide({
    design: {
      bg
    },
    customCss: {
      antiAdBlocker
    }
  }) {
    const style = {
      background: bg
    };
    return {
      className: antiAdBlocker === "y" ? undefined : "rcb-tb-bottom",
      style
    };
  }

}

export { BlockerInlineStyles };