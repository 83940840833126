class BlockerFooterInlineStyles {
  static footerContainer({
    layout: {
      type,
      dialogBorderRadius
    },
    design,
    footerDesign: {
      inheritBg,
      bg,
      inheritTextAlign,
      textAlign,
      padding,
      fontSize,
      fontColor
    },
    customCss: {
      antiAdBlocker
    }
  }) {
    const style = {
      padding: padding.map(p => "".concat(p, "px")).join(" "),
      background: inheritBg ? design.bg : bg,
      borderRadius: type === "dialog" ? "0 0 ".concat(dialogBorderRadius, "px ").concat(dialogBorderRadius, "px") : undefined,
      fontSize: +fontSize,
      color: fontColor,
      textAlign: inheritTextAlign ? design.textAlign : textAlign
    };

    if (type === "dialog" && design.borderWidth > 0) {
      style.borderBottom = "".concat(design.borderWidth, "px solid ").concat(design.borderColor);
      style.borderLeft = style.borderBottom;
      style.borderRight = style.borderBottom;
    }

    return {
      className: antiAdBlocker === "y" ? undefined : "rcb-footer-container",
      style
    };
  }

  static footer({
    customCss: {
      antiAdBlocker
    }
  }) {
    const style = {
      margin: "auto",
      lineHeight: 1.8
    };
    return {
      className: antiAdBlocker === "y" ? undefined : "rcb-footer",
      style
    };
  }

  static footerSeparator({
    layout: {
      type
    },
    design,
    footerDesign: {
      borderWidth,
      borderColor
    },
    customCss: {
      antiAdBlocker
    }
  }) {
    const style = {
      height: +borderWidth,
      background: borderColor
    };

    if (type === "dialog" && design.borderWidth > 0) {
      style.borderLeft = "".concat(design.borderWidth, "px solid ").concat(design.borderColor);
      style.borderRight = style.borderLeft;
    }

    return {
      className: antiAdBlocker === "y" ? undefined : "rcb-footer-separator",
      style
    };
  }

}

export { BlockerFooterInlineStyles };